export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_DETAILS_SUBMITTED = "SET_DETAILS_SUBMITTED";


export const ROLE_ADMIN = "Admin";
export const ROLE_DEALER = "Dealer";
export const ROLE_CUSTOMER = "Customer";

export const DEFAULT_PRODUCTS = ["Walking Frame", "Rollators", "Wheelchair", "Riser Recliner Chairs", "Mobility Scooters"];
export const RATIOS = ["default", "seatHeight", "seatDepth", "floorToKnee", "elbow_height", "floorToKnee2"];
export const RATIO_VALUES = { default: 1.0, seatHeight: 1.0, seatDepth: 1.1, floorToKnee: 1.3, elbow_height: 1.0, floorToKnee2: 1.0 };